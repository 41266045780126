import {
    FontPalette,
    FontFiles,
    createTheme,
    ColorPalette,
    Theme,
    DefaultTheme,
} from "@assets/theme/Theme"
import { EncodedImages } from "../images/encodedImages"
import { Buffer } from "buffer"

// create the default theme

const fontPalette: FontPalette = {
    //
    regular: "polls-Poppins-Regular",
    semiBold: "polls-Poppins-SemiBold",
    bold: "polls-Poppins-Bold",

    pollsRegular: "sf-pro-display",
    pollsSemiBold: "sf-pro-display-semibold",
    pollsBold: "sf-pro-display-bold",
}

const fontFiles: FontFiles = {
    // sf pro font files are always included

    // add custom fonts
    "polls-Poppins-Regular": require("../fonts/Poppins-Regular.ttf"),
    "polls-Poppins-SemiBold": require("../fonts/Poppins-SemiBold.ttf"),
    "polls-Poppins-Bold": require("../fonts/Poppins-Bold.ttf"),

    // set custom icon font, or include default
    // default
    IcoMoon: require("@assets/icons/icomoon.ttf"),

    // custom
    // IcoMoon: require("../icons/icomoon.ttf"),
}

const lightColorPalette: ColorPalette = {
    // main colors
    primary: "#DD0F62", // main color of the theme used in general
    secondary: "#ebebeb", // accent color

    // backgrounds
    screenBackground: "#fff", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#fff", // all cards, option cards, settings cards, modals, drawers
    overlay: "#fff", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#DD0F62", // e.g. tab bar icons
    secondaryIcon: "#9b9b9b", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#DD0F62",
    primaryCtaBackgroundInactive: "#9b9b9b",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#fff",
    secondaryCtaTextActive: "#111D30",
    secondaryCtaTextDisabled: "#111D30",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#111D30",
    floatingCTATextDisabled: "#9b9b9b",

    // text
    t1: "#343E57", //all primary text, such as poll title, option card title, etc.
    t2: "#535C71", //all secondary text, such as help text, option subtitle, option details
    t3: "#9b9b9b", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#fff",
    placeholder: "#ebebeb", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#000", //color to draw attention such as submit to save changes banner
    divider: "#E0E0E0", //divider lines
    banner: "#EFF5FF", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",
}

const darkColorPalette: ColorPalette = {
    // main colors
    primary: "#17CE85", // main color of the theme used in general
    secondary: "#ebebeb", // accent color

    // backgrounds
    screenBackground: "#0E0E0F", // color of screen on mobile, or poll card on desktop, does not determine backdrop outside the poll card
    cardBackground: "#1C1C20", // all cards, option cards, settings cards, modals, drawers
    overlay: "#000000", // color used behind a mondal to "blur" the background

    // icons
    primaryIcon: "#17CE85", // e.g. tab bar icons
    secondaryIcon: "#ebebeb", // e.g. re-order, close

    // buttons
    primaryCtaBackground: "#17CE85",
    primaryCtaBackgroundInactive: "#5C5C5C",
    primaryCtaTextActive: "#FFFFFF",
    primaryCtaTextDisabled: "#FFFFFF",

    secondaryCtaBackground: "#1C1C20",
    secondaryCtaTextActive: "#17CE85",
    secondaryCtaTextDisabled: "#FFFFFF",

    tertiaryCtaBackground: "#", // tertiary cta (is this floating, or do we have tertiary AND floating)
    tertiaryCtaTextActive: "#",
    tertiaryCtaTextDisabled: "#",

    floatingCTAText: "#17CE85",
    floatingCTATextDisabled: "#9b9b9b",

    // text
    t1: "#FFFFFF", //all primary text, such as poll title, option card title, etc.
    t2: "#ebebeb", //all secondary text, such as help text, option subtitle, option details
    t3: "#5C5C5C", //help text and option details
    regularText: "#343E57",
    t1Inverted: "#FFFFFF",
    placeholder: "#5C5C5C", //placeholder text before user types poll or option titles
    linkText: "#007AFE",
    error: "#FE281C",

    // misc
    shadow: "#1C1C1C", // color needed for dark shadows
    flag: "#FF9500", //color to draw attention such as submit to save changes banner
    divider: "#FFFFFF", //divider lines
    banner: "#1C1C20", //banners such as the paste links banner
    destructive: "#FE281C", //for deleting things, typically red
    success: "#05CB4F",

    // Polls Colors (our palette)
    pollPrimaryTextColor: "#343E57",
}

enum ThemeMode {
    light = "light",
    dark = "dark",
}

const hexToRgbArrayString = (hex: string): string => {
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = "0x" + c.join("")
        const n = Number(c)
        const rgbArrayString = `[${((n >> 16) & 255) / 255}, ${
            ((n >> 8) & 255) / 255
        }, ${(n & 255) / 255}]`
        // console.log("RGBA SHADOW: ", rgba)
        return rgbArrayString
    }
    return "[0,0,0]"
}

const hexToRgbA = (hex: string, alpha: number) => {
    let c
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("")
        if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = "0x" + c.join("")
        const n = Number(c)
        const rgba = `rgba(${[(n >> 16) & 255, (n >> 8) & 255, n & 255].join(
            ",",
        )},${alpha})`
        // console.log("RGBA SHADOW: ", rgba)
        return rgba
    }
    return "rgba(0,0,0,0)"
}

const createDynamicLottie = ({
    lottie,
    primaryColor,
}: {
    lottie: unknown
    primaryColor: string
}): string => {
    const lottieString = JSON.stringify(lottie)
    const rgbArrayString = hexToRgbArrayString(primaryColor)
    const updatedLottieString = lottieString.replace(
        // eslint-disable-next-line quotes
        /"{{ primaryColor }}"/g,
        rgbArrayString,
    )
    const dynamicLottie = JSON.parse(updatedLottieString)
    return dynamicLottie as string
}

const createDynamicTheme = ({
    mode,
    primaryColor,
}: {
    mode: ThemeMode
    primaryColor: string
}) => {
    let colorPalette =
        mode === ThemeMode.light ? lightColorPalette : darkColorPalette

    colorPalette = {
        ...colorPalette,
        primary: primaryColor,
        primaryIcon: primaryColor,
        primaryCtaBackground: primaryColor,
        secondaryCtaTextActive: primaryColor,
        floatingCTAText: primaryColor,
    }

    return createTheme({
        fontPalette: fontPalette,
        fontFiles: fontFiles,
        colorPalette: colorPalette,
        fontSizeMultiplier: 1.0,
        lineHeightMultiplier: 1.0,
        overrides: {
            // color
            // font
            // shadow
            // cornerRadius
            // image
            // layout
            // lottie

            //old stuff to be removed
            // borderRadius
            // fontFiles

            color: {
                navHeaderBackground: colorPalette.screenBackground,
                optionCardProgressBarFill: colorPalette.primary,
                optionCardProgressBarBackground:
                    mode === ThemeMode.light
                        ? colorPalette.secondary
                        : "#3E3E3E",
                bottomNavigationFlagBackground: colorPalette.flag,
                ctaPrimaryActiveBackground: colorPalette.primaryCtaBackground,
                ctaPrimaryText: colorPalette.primaryCtaTextActive,
                ctaFloatingText: mode === ThemeMode.light ? "#343E57" : "#fff", //bottom navigation icon color
                optionCardCheckbox: colorPalette.primary, //checkbox on preview image and skeleton loader
                checkBox: colorPalette.primary,
                pollListCopyBannerIconBackground: colorPalette.cardBackground,
                pollListCopyBannerBackground: hexToRgbA(
                    colorPalette.primary,
                    0.3,
                ),
            },

            font: {
                //Poll Title
                pollTitleActiveWeb: {
                    ...DefaultTheme.font.pollTitleActiveWeb,
                    fontSize: 22,
                    lineHeight: 25,
                    color: colorPalette.t1,
                    fontFamily: fontPalette.semiBold,
                },

                pollTitleActiveMobile: {
                    ...DefaultTheme.font.pollTitleActiveMobile,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 21,
                    lineHeight: 20,
                    color: colorPalette.t1,
                },

                // Option Card
                optionCardTextVoteCount: {
                    ...DefaultTheme.font.optionCardTextVoteCount,
                    fontSize: 12,
                    lineHeight: 10,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t2,
                },
                defaultCardTemplateTitle: {
                    ...DefaultTheme.font.defaultCardTemplateTitle,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 13,
                    lineHeight: 18,
                    color: colorPalette.t1,
                },
                defaultCardTemplateSubtitle: {
                    ...DefaultTheme.font.defaultCardTemplateSubtitle,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 11,
                    lineHeight: 14,
                    color: colorPalette.primary,
                },
                defaultCardTemplateDescription: {
                    ...DefaultTheme.font.defaultCardTemplateDescription,
                    fontFamily: fontPalette.regular,
                    fontSize: 10,
                    lineHeight: 14,
                    color: colorPalette.t2,
                },
                defaultCardTemplateLink: {
                    ...DefaultTheme.font.defaultCardTemplateLink,
                    fontSize: 11,
                    lineHeight: 10,
                    fontFamily: fontPalette.semiBold,
                },

                ctaPrimaryText: {
                    ...DefaultTheme.font.ctaPrimaryText,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 18,
                },

                // Bottom Navigation Bar //
                bottomNavigationFlagText: {
                    ...DefaultTheme.font.bottomNavigationFlagText,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 13,
                    lineHeight: 13,
                    color: colorPalette.t1Inverted,
                }, // notification for the user after they have selected an option or made an update

                // poweredByHeader: {
                //     ...DefaultTheme.font.poweredByHeader,
                //     color: colorPalette.white,
                // },
                // poweredBySubheader: {
                //     ...DefaultTheme.font.poweredBySubheader,
                //     color: colorPalette.white,
                // },
                // sharePollTitle: {
                //     ...DefaultTheme.font.sharePollTitle,
                //     fontSize: 24,
                //     lineHeight: 30,
                //     fontFamily: fontPalette.bold,
                // },
                // createPollTitleWeb: {
                //     ...DefaultTheme.font.createPollTitleWeb,
                //     fontFamily: fontPalette.bold,
                //     fontSize: 24,
                //     lineHeight: 30,
                //     color: colorPalette.primaryText,
                // },

                // //Transcript View

                previewImagePollTitle: {
                    ...DefaultTheme.font.previewImagePollTitle,
                    color: colorPalette.t1,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 43,
                    lineHeight: 56,
                },
                previewImageOptionTitle: {
                    ...DefaultTheme.font.previewImageOptionTitle,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 32,
                    lineHeight: 40,
                },
                previewImageOptionSubtitle: {
                    ...DefaultTheme.font.previewImageOptionSubtitle,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.primary,
                    fontSize: 25,
                    lineHeight: 40,
                },
                previewImageVotesCountText: {
                    ...DefaultTheme.font.previewImageVotesCountText,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 24,
                    lineHeight: 24,
                },

                // //Step 2 Controls

                step2OptionsSelectedCounterValid: {
                    ...DefaultTheme.font.step2OptionsSelectedCounterValid,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.primary,
                    fontSize: 36,
                    lineHeight: 36,
                },
                // step2OptionsSelectedCounterInvalid: {
                //     ...DefaultTheme.font.step2OptionsSelectedCounterInvalid,
                //     fontFamily: fontPalette.semiBold,
                //     color: colorPalette.red,
                //     fontSize: 36,
                //     lineHeight: 36,
                // },
                step2OptionsCounterText: {
                    ...DefaultTheme.font.step2OptionsCounterText,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 14,
                    lineHeight: 14,
                },
                step2FilterAll: {
                    ...DefaultTheme.font.step2FilterAll,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.primary,
                    fontSize: 14,
                    lineHeight: 14,
                },
                step2FilterNone: {
                    ...DefaultTheme.font.step2FilterNone,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.primary,
                    fontSize: 14,
                    lineHeight: 14,
                },

                // //Step 2 Banner

                pollListCopyBannerTitle: {
                    ...DefaultTheme.font.pollListCopyBannerTitle,
                    fontFamily: fontPalette.bold,
                    color: colorPalette.t1,
                    fontSize: 16,
                    lineHeight: 16,
                },
                pollListCopyBannerSubtitle: {
                    ...DefaultTheme.font.pollListCopyBannerSubtitle,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 10,
                    lineHeight: 12,
                },

                // //Step 2 Option Card

                optionCardLinkTitle: {
                    ...DefaultTheme.font.optionCardLinkTitle,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 14,
                    lineHeight: 16,
                    color: colorPalette.t1,
                },

                optionCardLinkSubtitle: {
                    ...DefaultTheme.font.optionCardLinkSubtitle,
                    fontFamily: fontPalette.semiBold,
                    fontSize: 10,
                    lineHeight: 12,
                    color: colorPalette.primary,
                },

                // Share Poll Modal
                sharePollTitle: {
                    ...DefaultTheme.font.sharePollTitle,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 24,
                    lineHeight: 26,
                },
                sharePollPhoneTitle: {
                    ...DefaultTheme.font.sharePollPhoneTitle,
                    fontFamily: fontPalette.bold,
                    color: colorPalette.t1,
                    fontSize: 18,
                    lineHeight: 18,
                },
                sharePollSubtitle: {
                    ...DefaultTheme.font.sharePollSubtitle,
                    fontFamily: fontPalette.regular,
                    color: colorPalette.t2,
                    fontSize: 14,
                    lineHeight: 16,
                },
                sharePollScanTitle: {
                    ...DefaultTheme.font.sharePollScanTitle,
                    fontFamily: fontPalette.semiBold,
                    color: colorPalette.t1,
                    fontSize: 16,
                    lineHeight: 18,
                },
                sharePollScanDescription: {
                    ...DefaultTheme.font.sharePollScanDescription,
                    fontFamily: fontPalette.regular,
                    color: colorPalette.t2,
                    fontSize: 14,
                    lineHeight: 16,
                },
                notificationPollUrlText: {
                    ...DefaultTheme.font.notificationPollUrlText,
                    fontFamily: fontPalette.regular,
                    fontSize: 13,
                    lineHeight: 17,
                    color: colorPalette.t1,
                }, // e.g. Poll URL to copy in share drawer
            },

            image: {
                appLogo: require("../images/headerLogo.png"), // top left corner of desktop
                appBackgroundDesktop:
                    "linear-gradient(180deg, #84CFFF, #4DA1FF)",
                previewImageLogo: EncodedImages.previewLogo, // top left of preview image
                ...(mode === ThemeMode.light
                    ? {
                          // light mode
                          previewImageCard: require("@assets/images/preview-image/card.png"),
                          previewImageBackground: null,
                      }
                    : {
                          // dark mode
                          previewImageCard: require("@assets/images/preview-image/cardDark.png"),
                          previewImageBackground: require("../images/previewImageBackground.png"),
                          rememberSubmit: EncodedImages.rememberSubmit,
                          rememberSubmitBackground:
                              EncodedImages.rememberSubmitBackground,
                      }),
            },

            lottie: {
                checkmark: createDynamicLottie({
                    lottie: require("../lottie/checkmark-DO-NOT-CHANGE.json"),
                    primaryColor: primaryColor,
                }),
                //     checkmarkDone: require("../lottie/checkmark_green.json"),
                //     whiteSpinner: require("../lottie/WhiteLoadingSpinner.json"),
                spinner: createDynamicLottie({
                    lottie: require("../lottie/spinner-DO-NOT-CHANGE.json"),
                    primaryColor: primaryColor,
                }),
                //     trashCan: require("../lottie/trashCan.json"),
                checkBox: createDynamicLottie({
                    lottie: require("../lottie/checkBox-DO-NOT-CHANGE.json"),
                    primaryColor: primaryColor,
                }),
                createPollSuccess: createDynamicLottie({
                    lottie: require("../lottie/checkmark-DO-NOT-CHANGE.json"),
                    primaryColor: primaryColor,
                }),
                // createPollSuccess: require("../lottie/createPollSuccess.json"),
            },
        },
    })
}

export const TenantTheme = createDynamicTheme({
    mode: ThemeMode.light,
    primaryColor: "#000000",
})

export const getThemeByQueryParam = (queryParam: string): Theme => {
    const encodedThemeString = queryParam

    // // for testing ----------------------------------------------
    // const themeObject = { mode: "dark", primaryColor: "#ff0000" }
    // const themeJsonString = JSON.stringify(themeObject)
    // encodedThemeString = Buffer.from(themeJsonString).toString("base64")
    // console.log(encodedThemeString)
    // // ----------------------------------------------------------

    // Convert Base64 string back to JSON object
    const decodedThemeJsonString = Buffer.from(
        encodedThemeString,
        "base64",
    ).toString()
    let parsedThemeObject: any = {}
    try {
        parsedThemeObject = JSON.parse(decodedThemeJsonString)
    } catch {
        // do nothing
    }

    if (parsedThemeObject["mode"] && parsedThemeObject["primaryColor"]) {
        return createDynamicTheme(parsedThemeObject)
    } else {
        return TenantTheme
    }
}
